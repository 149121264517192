import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './homepage';
import Sweatshirt from './products/sweatshirt';
import RedFlag from './products/redflag';
import Goat from './products/goat';
import Shtrupopskii from './products/shtrupopskii';
import Astrofatida from './products/astrofatida';
import Davide from './products/davide';
import Hoodie from './products/hoodie';
import TermsAndPrivacy from './info';
import AstroHoodie from './products/astrohoodie';
// import Longsleeve from './products/longsleeve';
import Pasta from './products/pasta';
import Ribs from './products/ribs';
import Ale from './products/ale';
import Shorts from './products/shorts';
import Checkout from './Checkout'; // Import the Checkout component
import Success from './success';
import Cancel from './cancel';
import './App.css';
import Cookies from 'js-cookie';


function App() {
  const [showCookieWarning, setShowCookieWarning] = useState(false);
  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setShowCookieWarning(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    Cookies.set('cookieConsent', 'true', { expires: 7 });
    setShowCookieWarning(false);
  };

  return (
    <Router className='bg-black'>
      <div className='bg-black'>
        {/* Navigation bar */}
        <nav>
          <Link to="/">Home</Link>
          <Link to="/checkout">Cart</Link> {/* Add link to checkout */}
        </nav>

        <Routes className='bg-black'>
          <Route path="/" element={<HomePage />} />
          <Route path="/products/sweatshirt" element={<Sweatshirt />} />
          {/* <Route path="/products/astrofatida" element={<Astrofatida />} /> */}
          <Route path="/products/davide" element={<Davide />} />
          {/* <Route path="/products/hoodie" element={<Hoodie />} /> */}
          <Route path="/products/astrohoodie" element={<AstroHoodie />} />
          <Route path="/products/redflag" element={<RedFlag />} />
          <Route path="/products/goat" element={<Goat />} />
          <Route path="/products/shtrupopskii" element={<Shtrupopskii />} />
          <Route path="/products/pasta" element={<Pasta />} />
          <Route path="/products/ribs" element={<Ribs />} />
          {/* <Route path="/products/ale" element={<Ale />} /> */}
          <Route path="/products/shorts" element={<Shorts />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/checkout" element={<Checkout />} /> {/* Add checkout route */}
          <Route path="/info" element={<TermsAndPrivacy />} />
        </Routes>
      </div>
      {showCookieWarning && (
        <section className="fixed bottom-0 w-full border-t-2 border-yellow-300  bg-black">
          <div className="max-w-5xl px-4 py-8 mx-auto md:flex md:items-center md:gap-x-6">
            <div className="flex items-start md:items-center gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-red-500 shrink-0 ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
              </svg>
              <p className=" dark:text-yellow-300">
                We use cookies to enhance your user experience. By continuing to visit this site you agree to our use of cookies.
              </p>
            </div>

            <button 
              onClick={handleAcceptCookies} 
              className="items-center justify-center transition-colors duration-300 rounded-full flex hover:bg-gray-700 focus:outline-none md:w-7 md:h-7 md:p-0 md:flex
                        mt-3 w-full p-2 bg-yellow-300 text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>


          </div>
        </section>
      )}
    </Router>
  );
}

export default App;
